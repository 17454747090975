<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('comm.service.filters') }}</span>
                <span style="float: right">
                    <el-button
                        class="b-c-apply-filters"
                        type="primary"
                        size="mini"
                        icon="el-icon-check"
                        plain
                        @click.native="applyFilters"
                    >
                        {{ $t('general.apply') }}</el-button
                    >
                    <el-button
                        class="b-c-reset-filters"
                        type="warning"
                        size="mini"
                        icon="el-icon-refresh"
                        plain
                        @click.native="resetFilters"
                    >
                        {{ $t('general.reset') }}</el-button
                    >
                </span>
            </div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('comm.service.from') }}</div>
                    <el-date-picker
                        class="fullWidth"
                        value-format="yyyy-MM-dd"
                        v-model="filter_minDate"
                        type="date"
                        :placeholder="$t('comm.service.from-ph')"
                    >
                    </el-date-picker>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('comm.service.to') }}</div>
                    <el-date-picker
                        class="fullWidth"
                        value-format="yyyy-MM-dd"
                        v-model="filter_maxDate"
                        type="date"
                        :placeholder="$t('comm.service.to-ph')"
                    >
                    </el-date-picker>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('comm.service.search') }}</div>
                    <el-input
                        v-model="filter_products_model"
                        class="input-with-select"
                        :placeholder="$t('comm.service.search-ph')"
                        @keyup.enter.native="applyFilters"
                    >
                        <el-button
                            class="b-c-apply-filters"
                            slot="append"
                            icon="el-icon-search"
                            @click.native="applyFilters(true)"
                        ></el-button>
                    </el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('comm.service.solved') }}</div>
                    <el-select v-model="filter_solved" class="fullWidth">
                        <el-option :key="1" :value="1" :label="$t('comm.service.alreadySolved')"></el-option>
                        <el-option :key="0" :value="0" :label="$t('comm.service.unsolved')"></el-option>
                        <el-option :key="2" :value="2" :label="$t('comm.service.all')"></el-option>
                    </el-select>
                </el-col>
            </el-row>
        </el-card>

        <div class="separator"></div>

        <el-card class="box-card">
            <el-table :data="data" border style="width: 100%" size="mini">
                <el-table-column prop="id_unic" :label="$t('comm.service.unique-id')" width="70"> </el-table-column>
                <el-table-column prop="data" :label="$t('comm.service.date')" width="140"> </el-table-column>
                <el-table-column prop="nume" :label="$t('comm.service.name')"> </el-table-column>
                <el-table-column prop="nrfactura" :label="$t('comm.service.bill-nr')" width="120"> </el-table-column>
                <el-table-column prop="products_model" :label="$t('comm.service.product-model')"> </el-table-column>
                <el-table-column prop="products_name" :label="$t('comm.service.product-name')"> </el-table-column>
                <el-table-column prop="products_url" label="Link"> </el-table-column>
                <el-table-column :label="$t('comm.service.view')" fixed="right" width="105">
                    <template slot-scope="scope">
                        <router-link :to="{ path: `/comunicare/service_produs/${scope.row.id_unic}` }">
                            <el-button class="b-c-gotoaddress" size="mini" type="primary" icon="el-icon-info" plain
                                >{{ $t('general.details') }}
                            </el-button>
                        </router-link>
                    </template>
                </el-table-column>
            </el-table>
            <div class="mini-separator"></div>
            <div class="center">
                <el-pagination
                    layout="prev, pager, next, total"
                    :current-page="page"
                    :total="total"
                    :page-size="filter_step"
                    @current-change="changePage"
                >
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import communicationStore from '@/store/modules/communication';
import { call, sync } from 'vuex-pathify';

export default {
    data() {
        return {
            page: 1
        };
    },
    computed: {
        data: sync('communication/service@data'),
        total: sync('communication/service@total'),
        ...sync('communication/service@filters', {
            filter_start: 'start',
            filter_step: 'step',
            filter_minDate: 'minDate',
            filter_maxDate: 'maxDate',
            filter_products_model: 'products_model',
            filter_solved: 'solved'
        })
    },
    methods: {
        GetServiceRequests: call('communication/GetServiceRequests'),
        getServiceRequests() {
            this.GetServiceRequests();
        },
        changePage(page) {
            this.page = page;
            this.filter_start = this.filter_step * (this.page - 1);

            this.getServiceRequests();
        },
        applyFilters() {
            this.getServiceRequests();
        },
        resetFilters() {
            this.page = 1;

            this.filter_start = 0;
            this.filter_minDate = '';
            this.filter_maxDate = '';
            this.filter_products_model = '';
            this.filter_solved = 2;

            this.getServiceRequests();
        }
    },
    mounted() {
        this.getServiceRequests();
    },
    beforeCreate() {
        this.$store.registerModule('communication', communicationStore());
    },
    beforeDestroy() {
        this.$store.unregisterModule('communication');
    }
};
</script>
